import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
// import Call from '../components/Call';

const Home = props => {
  // const markdown = props.data.allMarkdownRemark.edges;
  const json = props.data.site.siteMetadata.features;
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Small Business Theme. Multiple content types using Markdown and JSON sources. Responsive design and SCSS. This is a beautiful and artfully designed starting theme."
        />
      </Helmet>
      <div className="intro pb-4">
        <div className="container">
          <h1>Your Sidekick for Teaching ESL</h1>
          <p>
            Stars 2 Apples helps online ESL teachers stay organized and helps
            engage students with exciting rewards and props.
          </p>
        </div>
      </div>

      {/* <div className="container pt-2">
        <Call button />
      </div> */}

      {/* <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Our Services</h2>
          </div>
          {markdown.map(edge => (
            <div
              key={edge.node.frontmatter.path}
              className="col-12 col-md-4 mb-1"
            >
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.frontmatter.path}>
                      {edge.node.frontmatter.title}
                    </Link>
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              View All Services
            </Link>
          </div>
        </div>
      </div> */}

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-4">Features</h2>
          </div>
          {json.map(edge => (
            <div key={edge.id} className="col-12 col-md-6 col-lg-6 mb-2">
              <div className="feature">
                {edge.image && (
                  <div className="feature-image">
                    <img src={withPrefix(edge.image)} />
                  </div>
                )}
                <h2 className="feature-title">{edge.title}</h2>
                <div className="feature-content">{edge.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Thing {
    site {
      siteMetadata {
        features {
          title
          description
          image
        }
      }
    }
  }
`;

export default Home;
